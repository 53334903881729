import {Component,OnInit} from '@angular/core';
import {SelectItem, ScheduleModule, TabViewModule, RatingModule} from 'primeng/primeng';
import { Router } from '@angular/router';
// import { GMapModule} from 'primeng/components/gmap/gmap';
import * as $ from 'jquery';

@Component({
    templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {

    constructor(private router: Router) { 
    }
    
    ngOnInit() {
        console.log('aaaaaaa')
    }
    dokter(){
        window.location.href = "/e-reservasi#/regonline?tab=dokter"
    }
    reservasi(){
        window.location.href = "/e-reservasi#/regonline"
    }
    bed(){
        // window.location.href = "https://app.rsiamalsehat.com/dashboard-bedah/informasibed/"
        window.location.href = "/e-reservasi#/regonline?tab=bed"
    }
    batalkanPerjanjian(){
        window.location.href = "/e-reservasi#/regonline?tab=cancelreservation"
    }
    wa(hp){
        window.open("https://wa.me/"+hp+"?text=Hallo%20Rumah%20Sakit%20Amal%20Sehat","_blank") 
    }
    wa2(hp){
        window.open("https://wa.me/"+hp+"?text=Hallo","_blank") 
    }
}