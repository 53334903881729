import { AuthGuard } from '../../helper';
import * as pMaster from './';
import { HomeComponent } from './home/home.component';



let session = JSON.parse(localStorage.getItem('user.data'));
export const pathMaster = [

    { path: '', redirectTo: 'reservasi', pathMatch: 'full' },
    //KIOS K
   
    //END KIOS K

    //Reservasi
    // { path: 'reservasi', component: pMaster.ReservasiOnlineComponent },
    // { path: 'home', component: pMaster.HomeComponent },
    { path: 'reservasi', component: pMaster.HomeComponent },
    { path: 'regonline', component: pMaster.ReservasiOnlineComponent },
    //END Reservasi


    { canActivate: [AuthGuard], path: '404', component: pMaster.NotFoundComponent },
    { canActivate: [AuthGuard], path: '**', redirectTo: '/404' },

];